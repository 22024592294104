.container {
  padding: 20px;
  background-color: #f9f9f9;
}

.header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.loading, .error {
  font-size: 18px;
  color: red;
}

.filter-container {
  margin-bottom: 20px;
}

.filter-container label {
  margin-right: 10px;
}

.filter-container select, .filter-container input {
  margin-right: 20px;
  padding: 5px;
  font-size: 16px;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

thead {
  background-color: #ddd;
}

th, td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
  vertical-align: middle;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

td img, td .MuiAvatar-root {
  margin-right: 10px;
  vertical-align: middle;
}
.container {
  margin-top: 120px; /* Adjust this value to match the height of your Navbar */
  padding: 20px;
}

.loading, .error {
  text-align: center;
  margin-top: 20px;
}

.filter-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}
