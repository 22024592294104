.NavRoot {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 5rem;
    position: fixed;
    top: 0;
    background-color: #ffffff; /* Change this to your desired background color */
    z-index: 1000; /* Adjust the z-index as needed */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: rgb(255, 255, 255);

    a {
        text-decoration: none;
        color: rgb(0, 0, 0);
    }
}

.logo {
    height: 90px; /* Adjust the size as needed */
    width: auto;
}

.leftNav {
    display: flex;
    justify-content: center;
    align-items: center;
}

.middleNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.rightNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.notiBtn {
    background: #ffffff;
    border: 1px solid black;
    color: black;
    padding: 5px 15px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuIcon {
    display: none;
    color: black;
}

.rightNav div:hover {
    cursor: pointer;
}

.rightNav .loginBtn {
    background-color: #FFD829;
    color: rgb(0, 0, 0);
    border: 1px solid transparent; /* Add a transparent border initially */
    padding: 10px 2rem;
    border-radius: 6px;
    transition: background-color 0.3s, color 0.3s, border 0.3s; /* Add transition for smoother effect */
}

.rightNav .loginBtn:hover {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    cursor: pointer;
    border: 1px solid black; /* Change border color on hover */
}

/* Mobile Devices (up to 767px) */
@media only screen and (max-width: 767px) {
    .NavRoot {
        .leftNav {}

        .rightNav {
            display: none;
        }

        .middleNav {
            display: none;
        }

        .menuIcon {
            display: flex;
        }
    }
}

/* Tablet Devices (768px to 1023px) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .NavRoot {
        .leftNav {}

        .rightNav {
            display: none;
        }

        .middleNav {
            display: none;
        }

        .menuIcon {
            display: flex;
        }
    }
}

/* Desktop Devices (1024px and above) */
@media only screen and (min-width: 1024px) {
    /* CSS styles for desktop devices */
}
