.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  color: #333;
}

.search-filter-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-bar {
  flex: 1;
  padding: 10px;
  font-size: 1em;
  margin-right: 10px;
}

select {
  flex: 1;
  padding: 10px;
  font-size: 1em;
  margin-right: 10px;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.loading {
  text-align: center;
  font-size: 1.2em;
}

.error {
  text-align: center;
  color: red;
  font-size: 1.2em;
}
