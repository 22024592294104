.footerRoot {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    flex-direction: column;
    background-color: #8bc6ec;
    background-image: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
    color: black;

    .wrapperTop {
        padding: 3rem 0;
        border-top: 1px solid;
        border-image: linear-gradient(to right,
                #ffffff2f,
                rgba(0, 0, 0, 0.637),
                #ffffff2f) 1;
        width: 80%;

        display: flex;
        align-items: start;
        justify-content: space-between;

        .title {
            margin-bottom: 10px;
        }

        .footerLinkContainer {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .footerLinkContainer div {
            cursor: pointer;
            text-decoration: none;
        }
    }

    .wrapperBottom {
        padding: 3rem 0;
        border-top: 1px solid;
        border-image: linear-gradient(to right,
                #ffffff2f,
                rgba(0, 0, 0, 0.637),
                #ffffff2f) 1;
        width: 80%;
        display: flex;
        align-items: end;
        justify-content: space-between;

        .logoAndSocial {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 2rem;

            .social {
                display: flex;
                gap: 2rem;
            }

            .social svg {
                cursor: pointer;
            }
        }
    }
}